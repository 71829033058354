<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
            :module_name="page.name"
            :record="record"
            @change="save"
            @archive="archive"
            :loading="loading"
            :model="page.model">
          <template v-slot:main_fields>
            <template v-for="field in usual_fields">
              <base-field
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
            </template>
            <base-tags v-model="record.tags" :module_name="page.name"/>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row v-if="record.sessions && record.sessions.length > 0">
      <v-col class="pa-3">
        <v-card :disabled="loading" :loading="loading">
          <v-card-title>
            <v-spacer/>
            <base-link
                :label="$store.getters.translate('link') + ' ' + $store.getters.translate('workers')"
                @change="linkOrCreateRecord"
                module_name="workers"
                :excluded_ids="$lodash.map(record.workers, 'id')"/>
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>{{ $store.getters.translate('candidate_name') }}</th>
                    <th v-for="(session, session_index) in record.sessions" :key="session_index">{{ session.name }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(worker, worker_index) in record.workers" :key="worker_index">
                    <td style="width: 200px;">
                      <a @click="$router.push('/workers/' + worker.id)">{{ worker.name }}</a>
                    </td>
                    <td style="width: 200px;" v-for="(session, session_index) in record.sessions" :key="session_index">
                      <v-text-field
                          flat
                          v-model="worker.pivot.results[session.name]"
                          dense
                          class="centered-input"
                          placeholder="-"
                          single-line
                          @change="updateResults(worker.pivot)"/>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels>
          <base-table-includable ref="notes_table" :page="page" :parent_module_id="record.id" relation_name="notes" @create="createNote" @edit="editNote" @change="load"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <base-create-dialog modal_name="create_note" ref="create_note" :page="notes_page" :files_required="true" :multiple_files="true" @refresh="load"/>
    <base-edit-dialog modal_name="edit_note" ref="edit_note" :page="notes_page" :files_required="true" :multiple_files="true" @refresh="load"/>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import BaseCreateDialog from "../../components/commonComponents/BaseCreateDialog.vue";
import BaseEditDialog from "../../components/commonComponents/BaseEditDialog.vue";
import helpFunctions from "../../plugins/helpFunctions";
import BaseLink from "../../components/commonComponents/BaseLink.vue";

export default {
  components: {
    BaseLink,
    BaseEditDialog,
    BaseCreateDialog,
    BaseTableIncludable,
    BaseTabPanel,
    BaseBreadcrumb,
  },
  data() {
    return {
      page: helpFunctions.pages.trainings,
      notes_page: helpFunctions.pages.notes,
      record: {},
      usual_fields: [],
      tags_required: false,
      loading: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      this.updateIncludableTables();
      await this.getFields();
    },
    updateIncludableTables() {
      this.$refs.notes_table.records = this.record.notes;
      this.$refs.notes_table.selected = [];
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
            await this.load();
          }
          this.loading = false;
        }
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    createNote() {
      this.$refs.create_note.load();
      this.$refs.create_note.record.parent_model = 'Training';
      this.$refs.create_note.record.parent_model_id = this.record.id;
    },
    editNote(item) {
      this.$refs.edit_note.load(item.id);
    },
    updateResults(pivot) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/update-training-results", pivot)
          .then(() => {
            this.$toasted.success(this.$store.getters.translate("success"));
          })
          .catch((error) => {
            this.$toasted.error(error);
          });
    },
    linkOrCreateRecord(objects) {
      if(!this.loading) {
        this.loading = true;
        let result = {
          attach: this.$lodash.map(objects, "id"),
          model_id: this.record.id,
          relation_name: "workers",
          objects: objects,
          model: "training"
        };
        this.$http
            .post(this.$store.getters.appUrl + "v2/bulk/attach", result)
            .then((response) => {
              this.loading = false;
              if (response.data === 'created' || response.data === 'attached') {
                this.$toasted.success(this.$store.getters.translate("success"));
                this.load();
              } else {
                this.$toasted.error(this.$store.getters.translate(response.data));
              }
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
      }
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.loading = false;
    },
  },
};
</script>
